@tailwind base;
@tailwind components;
@tailwind utilities;

.landing-page {
  .header {
    .logo {
      color: #AD1E2E;
    }
    .menu {
      .menu-item {
        @apply text-gray-700 mx-2 hover:text-gray-900 transition duration-300;
      }
    }
  }
  
  .hero {
    text-align: left;
    .hero-image {
      max-width: 100%;
      height: auto;
    }
    .btn {
      background-color: #F69A29;
      &:hover {
        background-color: #AD1E2E;
      }
    }
  }

  .features {
    .feature {
      .feature-image {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .use-case {
    text-align: left;
  }

  .blend-demo .reset-button {
    width: 100%;
    background-color: #333;
    padding: 10px;
    color: #fafafa;
  }

  .blend-demo .reset-button:hover {
    background-color: #555;
  }

  .blend-demo .reset-button:active {
    background-color: #777;
  }

  .blend-demo .reset-button:focus {
    outline: none;
  }

  .blend-selector {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  .footer {
    .footer-link {
      @apply text-gray-600 mx-4 hover:text-gray-800;
    }
  }
}