.cbb-container {
	/*font-family: Arial, sans-serif;*/
	padding: 20px;
	/*border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;*/
	margin: 0 auto;
	overflow: hidden;
}

.cbb-search-input {
	width: 100%;
	padding: 10px;
	border: 1px solid #cccccc;
	border-radius: 4px;
	margin-bottom: 15px;
	font-size: 16px;
}

.cbb-search-container .suggestion-item {
	padding: 10px;
	cursor: pointer;
}

.cbb-search-container .suggestion-item:hover {
	background-color: #f0f0f0;
}

.cbb-container ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.cbb-container ul li {
	padding: 10px;
	border-bottom: 1px solid #e0e0e0;
	cursor: pointer;
}

.cbb-container ul li:hover {
	background-color: #f9f9f9;
}

.cbb-title {
	font-size: 1.5rem;
	margin-bottom: 20px;
	font-weight: bold;
	color: #333333;
	text-align: center;
}

.cbb-item-list {
	margin-bottom: 20px;
}

.cbb-item-list h3 {
	font-size: 1.2rem;
	margin-bottom: 10px;
	font-weight: bold;
	color: #555555;
}

.cbb-blend {
	margin-bottom: 20px;
}

.cbb-blend h3 {
	font-size: 1.2rem;
	margin-bottom: 10px;
	font-weight: bold;
	color: #555555;
}

.cbb-blend p {
	margin-bottom: 10px;
	font-weight: bold;
	color: #333333;
}

.blend-item {
	display: flex;
	flex-direction: column;
	padding: 10px;
	border-bottom: 1px solid #e0e0e0;
	flex-wrap: wrap;
}

.blend-item-details {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 10px;
}

.item-image {
	width: 40px;
	height: 40px;
	margin-right: 10px;
	border-radius: 4px;
}

.remove-button {
	border: none;
	background: none;
	cursor: pointer;
	font-size: 16px;
	padding: 0;
	margin-left: 10px;
}

.blend-item-controls {
	display: flex;
	align-items: center;
	width: 100%;
}

.percentage-input {
	width: 60px;
	padding: 5px;
	margin-right: 10px;
	font-size: 16px;
	border: 1px solid #cccccc;
	border-radius: 4px;
	text-align: center;
	margin-bottom: 5px;
}

.percentage-range {
	width: 100%;
	margin-bottom: 5px;
}

.error {
	color: #ff4d4d;
	font-weight: bold;
	margin-top: 10px;
	text-align: center;
}

.load-blends-button,
.reset-button {
	width: 100%;
	margin-bottom: 20px;
	cursor: pointer;
}

.load-blends-button:hover,
.reset-button:hover {
}

.cbb-search-wrapper {
	width: 100%; /* or any specific width you need */
	overflow: hidden;
	display: block; /* ensures block level formatting */
	box-sizing: border-box; /* includes padding and border in element's total width and height */
}

.cbb-search-item {
	display: flex;
	align-items: center;
	padding: 10px;
	border-bottom: 1px solid #e0e0e0;
	cursor: pointer;
}

.blend-name-container {
	margin-bottom: 20px;
}

.blend-name-input,
.blend-description-input {
	width: 100%;
	padding: 10px;
	margin-bottom: 10px;
	font-size: 16px;
	border: 1px solid #cccccc;
	border-radius: 4px;
}

.merchant-blend-select,
.size-select {
	width: 100%;
	padding: 10px;
	margin-bottom: 20px;
	font-size: 16px;
	border: 1px solid #cccccc;
	border-radius: 4px;
}

.blend-count-wrapper {
	flex: 1;
	text-align: right;
}
.cbb-size-select {
	margin: 10px 0px;
}

.dropdown-container {
	position: relative;
	width: 100%;
	margin-bottom: 6px;
  }
  
  .dropdown-header {
	padding: 10px;
	background: #fff;
	border: 1px solid #ccc;
	cursor: pointer;
  }
  
  .dropdown-list {
	position: absolute;
	width: 100%;
	background: #fff;
	border: 1px solid #ccc;
	max-height: 200px;
	overflow-y: auto;
	list-style: none;
	margin: 0;
	padding: 0;
	z-index: 999;
  }
  
  .dropdown-list-item {
	padding: 10px;
	cursor: pointer;
	display: flex;
	align-items: center;
  }
  
  .dropdown-list-item:hover {
	background: #f0f0f0;
  }
  
  .dropdown-list-item img {
	width: 40px;
	height: 40px;
	margin-right: 10px;
  }
  
  .cbb-search-wrapper input {
	width: 100%;
	padding: 10px;
	border: 1px solid #e0e0e0;
	border-top: 0px;
	border-bottom: 0px;
  }

@media (max-width: 480px) {
	.blend-item-details,
	.blend-item-controls {
		flex-direction: column;
		align-items: flex-start;
	}

	.percentage-input,
	.percentage-range {
		width: 100%;
		margin-bottom: 10px;
	}

	.remove-button {
		margin-top: 10px;
		margin-left: 0;
	}
}


.percentage-range {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%;
  height: 10px;
  background: #ddd; /* Track background color */
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

/* The thumb (the grabber) */
.percentage-range::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 18px;
  height: 18px;
  background: #4CAF50; /* Thumb color */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%; /* Make thumb round */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); /* Optional shadow */
}

.percentage-range::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4CAF50; /* Thumb color */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%; /* Make thumb round */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); /* Optional shadow */
}

.percentage-range::-ms-thumb {
  width: 25px;
  height: 25px;
  background: #4CAF50; /* Thumb color */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%; /* Make thumb round */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); /* Optional shadow */
}

/* Selected part of the range */
.percentage-range::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #4CAF50 0%, #4CAF50 var(--value), #ddd var(--value), #ddd 100%);
}

.percentage-range::-moz-range-progress {
  background: #4CAF50; /* Selected part color */
}

.percentage-range::-moz-range-track {
  background: #ddd; /* Unselected part color */
}

.percentage-range::-ms-fill-lower {
  background: #4CAF50; /* Selected part color */
}

.percentage-range::-ms-fill-upper {
  background: #ddd; /* Unselected part color */
}