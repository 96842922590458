@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles for common elements */
body {
  @apply bg-white text-gray-900 font-sans min-h-screen;
}

h1, h2, h3, h4, h5, h6 {
  @apply font-bold leading-tight text-gray-900;
}

h1 {
  @apply text-4xl md:text-5xl lg:text-6xl;
}

h2 {
  @apply text-3xl md:text-4xl lg:text-5xl;
}

h3 {
  @apply text-2xl md:text-3xl lg:text-4xl;
}

h4 {
  @apply text-xl md:text-2xl lg:text-3xl;
}

h5 {
  @apply text-lg md:text-xl lg:text-2xl;
}

h6 {
  @apply text-base md:text-lg lg:text-xl;
}

p {
  @apply mb-4 text-gray-700 leading-relaxed;
}

a {
  @apply text-[#F69A29] hover:text-[#AD1E2E] transition duration-300;
}

ul {
  @apply list-disc list-inside;
}

ol {
  @apply list-decimal list-inside;
}

li {
  @apply mb-2;
}

button {
  @apply bg-[#F69A29] text-white py-2 px-4 rounded hover:bg-[#AD1E2E] transition duration-300;
}

/* Additional utilities */
.container {
  @apply px-4 max-w-screen-xl mx-auto;
}

.section {
  @apply py-20;
}

.header {
  @apply bg-[#F69A29] text-white;

  .logo {
    color: #AD1E2E;
  }

  .menu {
    .menu-item {
      @apply text-white mx-2 hover:text-gray-900 transition duration-300;
    }
  }
}

footer {
  @apply bg-[#F69A29] text-white;

  .logo {
    color: #AD1E2E;
  }

  .menu {
    .menu-item {
      @apply text-white mx-2 hover:text-gray-900 transition duration-300;
    }
  }
}

header h1 {
  @apply text-4xl font-bold;
}

h1, h2, h3, h4, h5, h6 {
  @apply mb-2 text-3xl sm:text-4xl font-extrabold tracking-tight text-gray-600;
}

p {
  @apply mb-4 text-lg text-gray-500;
}

/* Full height layout */
#root, .app-container {
  @apply min-h-screen flex flex-col;
}

main {
  @apply flex-1;
}
