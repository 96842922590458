.blog-archive {
    .pagination {
      .page-item {
        transition: background-color 0.3s, color 0.3s;
  
        .page-link {
          &:hover {
            background-color: #AD1E2E;
            color: white;
          }
        }
      }
    }
  }
  