.single-blog-post {
    .post-content {
      p {
        @apply mb-4;
      }
      h2 {
        @apply text-3xl font-bold my-4;
      }
      ul, ol {
        @apply list-inside my-4;
      }
      li {
        @apply mb-2;
      }
      a {
        @apply text-[#F69A29] hover:text-[#AD1E2E] transition duration-300;
      }
    }
  }
  